<template>
  <section class="bg-slate-100 overflow-hidden">
    <div class="max-w-7xl mx-auto pt-24 pb-12">
      <div class="flex flex-col lg:flex-row items-center px-4 lg:px-0">
        <div class="w-full lg:w-1/3 lg:border-r-2 pr-8">
          <h4 class="text-3xl gradient-clip lg:text-right text-black">{{ $t('pages.home.about.we_are_lucky') }}
            <span>{{ $t('pages.home.about.amazing_clients') }}</span> {{ $t('pages.home.about.and_well_established_partners') }}
          </h4>
        </div>
        <div class="w-full lg:w-1/2 mt-4 lg:mt-0 lg:pl-8">
          <p class="text-black">{{ $t('pages.home.about.we_work_closely') }} {{ $t('pages.home.about.high_end_marketing_agencies') }} {{ $t('pages.home.about.in_Canada') }} {{ $t('pages.home.about.we_help_them_create') }}
            {{ $t('pages.home.about.powerful_digital_campaigns') }} {{ $t('pages.home.about.biggest_brands_world') }}.
          </p>
        </div>
      </div>
    </div>
    <div class="lg:max-w-6xl w-full mx-auto ">
      <Carousel :breakpoints="breakpoints" :items-to-show="2" :autoplay="3000" wrap-around snap-align="start" class="clients-wrapper">
        <Slide key="logo1"><NuxtImg format="webp" width="200" height="200" provider="cloudinary" loading="lazy" src="/images/client6.png" alt="Diesel"/></Slide>
        <Slide key="logo2"><NuxtImg format="webp" width="200" height="200" provider="cloudinary" loading="lazy" src="/images/client4.png" alt="Haribo"/></Slide>
        <Slide key="logo3"><NuxtImg format="webp" width="200" height="200" provider="cloudinary" loading="lazy" src="/images/clientnew4.png" alt="Nike"/></Slide>
        <Slide key="logo4"><NuxtImg format="webp" width="200" height="200" provider="cloudinary" loading="lazy" src="/images/clientnew3.png" alt="ThinkShit"/></Slide>
        <Slide key="logo12"><NuxtImg format="webp" width="200" height="200" provider="cloudinary" loading="lazy" src="/images/client16.png" alt="Hawk"/></Slide>
        <Slide key="logo5"><NuxtImg format="webp" width="200" height="200" provider="cloudinary" loading="lazy" src="/images/client2.png" alt="Kingstar"/></Slide>
        <Slide key="logo6"><NuxtImg format="webp" width="200" height="200" provider="cloudinary" loading="lazy" src="/images/client14.png" alt="Twitter"/></Slide>
        <Slide key="logo7"><NuxtImg format="webp" width="200" height="200" provider="cloudinary" loading="lazy" src="/images/client5.png" alt="JB Conseil"/></Slide>
        <Slide key="logo8"><NuxtImg format="webp" width="200" height="200" provider="cloudinary" loading="lazy" src="/images/client7.png" alt="eOceans"/></Slide>
        <Slide key="logo9"><NuxtImg format="webp" width="200" height="200" provider="cloudinary" loading="lazy" src="/images/client11.png" alt="Skills"/></Slide>
        <Slide key="logo10"><NuxtImg format="webp" width="200" height="200" provider="cloudinary" loading="lazy" src="/images/client17_2.png" alt="NSI Solution"/></Slide>
        <Slide key="logo11"><NuxtImg format="webp" width="200" height="200" provider="cloudinary" loading="lazy" src="/images/client12.png" alt="Webcampus"/></Slide>
        <Slide key="logo12"><NuxtImg format="webp" width="200" height="200" provider="cloudinary" loading="lazy" src="/images/client15.png" alt="National"/></Slide>
      </Carousel>
    </div>
    <div class="2xl:max-w-7xl xl:max-w-6xl py-8 mx-auto pb-24">
      <h4 class="xl:text-6xl lg:text-5xl text-3xl gradient-clip text-black max-w-lg text-center mx-8 lg:mx-auto mt-8 mb-16" v-html="$t('pages.home.about.about_us')">
      </h4>
      <div class="2xl:max-w-7xl xl:max-w-6xl p-8 mx-auto relative">
        <div id="review1"
             class="review-bubble pt-8 lg:pt-2 left-0 right-0 bottom-0 w-11/12 lg:w-auto mx-auto lg:mx-0 flex items-center max-w-lg bg-white absolute p-2 rounded-xl shadow-xs lg:left-12">
          <img src="~/assets/images/stars.png" class="w-1/4  my-2 absolute right-0 top-0" alt="5 stars">
          <div class="absolute lg:relative -top-10 left-2 lg:top-auto lg:left-auto w-1/4 pl-2 pr-4">
            <NuxtImg provider="cloudinary" format="webp" loading="lazy" width="100" height="100" src="/images/mary-price.jpg" class="rounded-full border-4 w-20 mx-auto border-slate-100" alt="client icon"/>
          </div>
          <div class="w-full lg:w-3/4 relative">
            <p class="text-black text-xs mt-1 mb-2"><b class="block text-sm mb-2">Would recommend</b><i>Rollin</i> took
              over a website project for us that had originally been developed by another agency, but had not met our
              needs in terms of functionality. They re-built and completed it very quickly and even made improvements to
              our user experience beyond what we had initially thought of. They were adaptable to emerging requests and
              easy to communicate with throughout the whole process.</p>
            <p class="text-black text-xs"><b>Mary price</b>, Marketing Director<br>Pulse Canada</p>
          </div>
        </div>
        <div id="review2"
             class="review-bubble pt-8 lg:pt-2 left-0 right-0 bottom-0 lg:bottom-auto w-11/12 lg:w-auto mx-auto lg:mx-0 left-point flex items-center max-w-md bg-white absolute p-2 rounded-xl shadow-xs lg:top-5 lg:left-auto lg:right-24">
          <img src="~/assets/images/stars.png" class="w-1/4  my-2 absolute right-0 top-0" alt="5 stars">
          <div class="absolute lg:relative -top-10 left-2 lg:top-auto lg:left-auto w-1/4 pl-2 pr-4">
            <NuxtImg provider="cloudinary" format="webp" loading="lazy" width="100" height="100" src="/images/josee.jpg" class="rounded-full border-4 w-20 mx-auto border-slate-100" alt="client icon" />
          </div>
          <div class="w-full lg:w-3/4 relative">
            <p class="text-black text-xs mt-1 mb-2"><b class="block text-sm mb-2">Visionnaire !</b>
              Le travail de <i>Rollin</i> est au dela de mes attentes , qualité , rapidité et visionnaire ! </p>
            <p class="text-black text-xs"><b>Josée Boulianne</b>, CEO<br>JB Conseil</p>
          </div>
        </div>
        <div id="review3"
             class="review-bubble pt-8 lg:pt-2 left-0 right-0 bottom-0 lg:bottom-auto w-11/12 lg:w-auto mx-auto lg:mx-0 flex items-center max-w-md bg-white absolute p-2 rounded-xl shadow-xs lg:top-0 lg:left-12 lg:right-auto">
          <img src="~/assets/images/stars.png" class="w-1/4  my-2 absolute right-0 top-0" alt="5 stars">
          <div class="absolute lg:relative -top-10 left-2 lg:top-auto lg:left-auto w-1/4 pl-2 pr-4">
            <NuxtImg provider="cloudinary" format="webp" loading="lazy" width="100" height="100" src="/images/natalie.jpg" class="rounded-full border-4 w-20 mx-auto border-slate-100" alt="client icon" />
          </div>
          <div class="w-full lg:w-3/4 relative">
            <p class="text-black text-xs mt-1 mb-2"><b class="block text-sm mb-2">Extraordinaire</b>Un support
              extraordinaire et rassurant. Chaque étape est réalisée avec professionnalisme. Merci à toute l'équipe de
              <i>Rollin</i></p>
            <p class="text-black text-xs"><b>Natalie Bertrand</b>, CEO<br>Gestion mieux-être</p>
          </div>
        </div>
        <div id="review4"
             class="review-bubble pt-8 lg:pt-2 left-0 right-0 bottom-0 w-11/12 lg:w-auto mx-auto lg:mx-0 left-point flex items-center max-w-md bg-white absolute p-2 rounded-xl shadow-xs lg:bottom-36 lg:left-auto lg:right-12">
          <img src="~/assets/images/stars.png" class="w-1/4  my-2 absolute right-0 top-0" alt="5 stars">
          <div class="absolute lg:relative -top-10 left-2 lg:top-auto lg:left-auto w-1/4 pl-2 pr-4">
            <NuxtImg provider="cloudinary" format="webp" loading="lazy" width="100" height="100" src="/images/man3.jpg" class="rounded-full border-4 w-20 mx-auto border-slate-100" alt="client icon" />
          </div>
          <div class="w-full lg:w-3/4 relative">
            <p class="text-black text-xs mt-1 mb-2"><b class="block text-sm mb-2">Excellent</b>Nous ne pouvons pas être plus
              satisfait des services fournis par <i>Rollin</i>. L'équipe est professionnelle et a travaillé efficacement pour
              terminer notre projet à temps et à l'intérieur de notre budget.</p>
            <p class="text-black text-xs"><b>Louis Drapeau</b>, CMO<br>Webcampus</p>
          </div>
        </div>
        <div id="review5"
             class="review-bubble pt-8 lg:pt-2 left-0 right-0 bottom-0 lg:bottom-auto w-11/12 lg:w-auto mx-auto lg:mx-0 flex items-center max-w-md bg-white absolute p-2 rounded-xl shadow-xs lg:right-auto lg:top-40">
          <img src="~/assets/images/stars.png" class="w-1/4  my-2 absolute right-0 top-0" alt="5 stars">
          <div class="absolute lg:relative -top-10 left-2 lg:top-auto lg:left-auto w-1/4 pl-2 pr-4">
            <NuxtImg provider="cloudinary" format="webp" loading="lazy" width="100" height="100" src="/images/woman4.jpg" class="rounded-full border-4 w-20 mx-auto border-slate-100" alt="client icon" />
          </div>
          <div class="w-full lg:w-3/4 relative">
            <p class="text-black text-xs mt-1 mb-2"><b class="block text-sm mb-2">Amazing experience</b>We had a great experience working with <i>Rollin</i>. They delivered an outstanding platform that worked exactly
              like we wanted and are still improving it to this day.</p>
            <p class="text-black text-xs"><b>Suzy Walsh</b>, Vice-president marketing<br>SB Canada</p>
          </div>
        </div>
        <div id="review6"
             class="review-bubble pt-8 lg:pt-2 left-0 right-0 bottom-0 lg:bottom-auto w-11/12 lg:w-auto mx-auto lg:mx-0 left-point flex items-center max-w-md bg-white absolute p-2 rounded-xl shadow-xs lg:top-72 lg:left-auto lg:right-16">
          <img src="~/assets/images/stars.png" class="w-1/4  my-2 absolute right-0 top-0" alt="5 stars">
          <div class="absolute lg:relative -top-10 left-2 lg:top-auto lg:left-auto w-1/4 pl-2 pr-4">
            <NuxtImg provider="cloudinary" format="webp" loading="lazy" width="100" height="100" src="/images/woman3.jpg" class="rounded-full border-4 w-20 mx-auto border-slate-100" alt="client icon" />
          </div>
          <div class="w-full lg:w-3/4 relative">
            <p class="text-black text-xs mt-1 mb-2"><b class="block text-sm mb-2">Professional, knowledgeable<br> and
              responsive</b>The team was professional, knowledgeable, and responsive to all of our requests. We would
              highly recommend <i>Rollin</i> to anyone looking for quality.</p>
            <p class="text-black text-xs"><b>Rita Beneen</b>, Brand supervisor<br>Diesel</p>
          </div>
        </div>
        <div id="review7"
             class="review-bubble pt-8 lg:pt-2 left-0 right-0 bottom-0 lg:bottom-auto w-11/12 lg:w-auto mx-auto lg:mx-0 left-point flex items-center max-w-md bg-white absolute p-2 rounded-xl shadow-xs lg:top-0  lg:left-auto lg:right-12">
          <img src="~/assets/images/stars.png" class="w-1/4  my-2 absolute top-0 right-0" alt="5 stars">
          <div class="absolute lg:relative -top-10 left-2 lg:top-auto lg:left-auto w-1/4 pl-2 pr-4">
            <NuxtImg provider="cloudinary" format="webp" loading="lazy" width="100" height="100" src="/images/christine.jpg" class="rounded-full border-4 w-20 mx-auto border-slate-100" alt="client icon"/>
          </div>
          <div class="w-full lg:w-3/4 relative">
            <p class="text-black text-xs mt-1 mb-2"><b class="block text-sm mb-2">Thank you</b><i>Rollin</i>
              enthusiastically helped us to improve important customer-facing features of our app — enabling us to meet
              the needs of ocean explorers around the world.</p>
            <p class="text-black text-xs"><b>Christine Ward-Page</b>, CEO<br>eOceans</p>
          </div>
        </div>
        <NuxtImg provider="cloudinary" loading="lazy" format="webp" width="300" height="450" src="/images/rollin-phone.png" class="mx-auto phone" alt="Mobile phone image" />
      </div>
    </div>
  </section>
</template>
<script>
import gsap from "gsap";
import 'vue3-carousel/dist/carousel.css'
import {Carousel, Slide, Pagination, Navigation} from 'vue3-carousel'

export default {
  data() {
    return {
      timeline: null,
      settings: {
        itemsToShow: 3,
        snapAlign: 'start',
        itemsToScroll: 1,
        autoplay:500,
        wrapAround:true,
        transition: 1000,
        mouseDrag: false,
        touchDrag: false,
      },
      breakpoints: {
        700: {
          itemsToShow: 2,
        },
        1024: {
          itemsToShow: 8,
        },
      },
    }
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation
  },
  mounted() {
    let ctx = gsap.context(() => {
      if(window.screen.width >= 1024){
        gsap.timeline({repeat: -1, repeatDelay: 0})
            .from("#review1", {y: 10, opacity: 0, duration: 0.5})
            .from("#review2", {y: 10, opacity: 0, duration: 0.5})
            .from("#review3", {y: 10, opacity: 0, duration: 0.5})
            .from("#review4", {y: 10, opacity: 0, duration: 0.5})
            .add('batch1')
            .to('#review1', {y: -10, opacity: 0, duration: 0.5, delay: 2}, 'batch1')
            .to('#review2', {y: -10, opacity: 0, duration: 0.5, delay: 2}, 'batch1')
            .to('#review3', {y: -10, opacity: 0, duration: 0.5, delay: 2}, 'batch1')
            .to('#review4', {y: -10, opacity: 0, duration: 0.5, delay: 2}, 'batch1')
            .from("#review5", {y: 10, opacity: 0, duration: 0.5})
            .from("#review7", {y: 10, opacity: 0, duration: 0.5})
            .from("#review6", {y: 10, opacity: 0, duration: 0.5})
            .add('batch2')
            .to('#review5', {y: -10, opacity: 0, duration: 0.5, delay: 2}, 'batch2')
            .to('#review6', {y: -10, opacity: 0, duration: 0.5, delay: 2}, 'batch2')
            .to('#review7', {y: -10, opacity: 0, duration: 0.5, delay: 2}, 'batch2');
      } else {
        gsap.timeline({repeat: -1, repeatDelay: 0})
            .from("#review1", {y: 10, opacity: 0, duration: 0.5})
            .to('#review1', {y: -10, opacity: 0, duration: 0.5, delay: 2})
            .from("#review2", {y: 10, opacity: 0, duration: 0.5})
            .to('#review2', {y: -10, opacity: 0, duration: 0.5, delay: 2})
            .from("#review3", {y: 10, opacity: 0, duration: 0.5})
            .to('#review3', {y: -10, opacity: 0, duration: 0.5, delay: 2})
            .from("#review4", {y: 10, opacity: 0, duration: 0.5})
            .to('#review4', {y: -10, opacity: 0, duration: 0.5, delay: 2})
            .from("#review5", {y: 10, opacity: 0, duration: 0.5})
            .to('#review5', {y: -10, opacity: 0, duration: 0.5, delay: 2})
            .from("#review6", {y: 10, opacity: 0, duration: 0.5})
            .to('#review6', {y: -10, opacity: 0, duration: 0.5, delay: 2})
            .from("#review7", {y: 10, opacity: 0, duration: 0.5})
            .to('#review7', {y: -10, opacity: 0, duration: 0.5, delay: 2})

      }

    });
    this.timeline = ctx
  },
  unmounted() {
    if(this.timeline !== null){
      this.timeline.revert()
    }
  }
}
</script>
